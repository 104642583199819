import axios from 'axios';

const API_KEY = 'OeYYICuZx3b21y6IvZfrrFNs'; // 替换为你的API Key
const SECRET_KEY = 'h6eygRfUwEvMCKRefh7WPJwbQC68xg2k'; // 替换为你的Secret Key

let accessToken = '';

const getAccessToken = async () => {
  const response = await axios.post('/api/oauth/2.0/token', null, {
    params: {
      grant_type: 'client_credentials',
      client_id: API_KEY,
      client_secret: SECRET_KEY
    }
  });
  accessToken = response.data.access_token;
};

const recognizeText = async (imageBase64) => {
  if (!accessToken) {
    await getAccessToken();
  }
  const response = await axios.post(`/api/rest/2.0/ocr/v1/accurate_basic?access_token=${accessToken}`, {
    image: imageBase64
  }, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
  return response.data.words_result;
};

export default recognizeText;