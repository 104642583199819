<template>

  <div class="ocr-demo">
    <h1>OCR识别DEMO</h1>
    <input type="file" @change="onFileChange" />
    <button @click="uploadImage">开始识别</button>
    <div class="con">
  <div>
    <img :src="img" alt="">
  </div>
      <div v-if="results.length">
        <ul>
          <li v-for="(result, index) in results" :key="index">{{ result.words }}</li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import recognizeText from '@/services/ocr';

export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      results: [],
      image: null,
      img: null
    };
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      this.img = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result.split(',')[1]; // 只获取base64部分
      };
      reader.readAsDataURL(file);
    },
    async uploadImage() {
      if (this.image) {
        try {
          this.results = await recognizeText(this.image);
        } catch (error) {
          console.error('识别失败', error);
        }
      } else {
        alert('请先选择图片');
      }
    }
  }

}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.con{
  margin-top: 30px;
  display: flex;
  div:nth-child(2){
    ul{
      li{
        text-align: left;
      }
    }
  }
  div{
    width: 50%;
    img{
      width: 50%;
    }
  }
}
</style>
